import { type RawRuleOf, createMongoAbility } from "@casl/ability"
import type { AppAbility, Permission } from "@shared/types/abilities"

// MongoAbility is not related to MongoDB,
// It's just a syntax to define permissions and conditions
export const createAbility = (rules: RawRuleOf<AppAbility>[]) =>
  createMongoAbility<AppAbility>(rules)

export const createAbilityFromPermissions = (
  permissions: Permission[]
): AppAbility => {
  const rules: RawRuleOf<AppAbility>[] = permissions.map((permission) => {
    const conditions = permission.conditions
      ? JSON.parse(permission.conditions)
      : {}

    return {
      action: permission.action,
      subject: permission.subject,
      conditions: conditions,
    }
  })

  return createAbility(rules)
}
