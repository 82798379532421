"use client"
import EmailVerificationPage from "@incmixf/auth/components/pages/email-verification-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/email-verification",
  component: () => <EmailVerificationPage />,
  validateSearch: (search: Record<string, string>) => ({
    email: (search["email"] as string) || "",
    code: (search["code"] as string) || "",
  }),
})
