"use client"
import NotFoundPage from "@incmixf/components/pages/not-found-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "*",
  component: () => <NotFoundPage />,
})
