import {
  BackpackIcon,
  ChevronDownIcon,
  ComponentInstanceIcon,
  CubeIcon,
  DashboardIcon,
  EnvelopeClosedIcon,
  FileTextIcon,
  LockClosedIcon,
  PersonIcon,
  QuestionMarkCircledIcon,
  ReaderIcon,
} from "@radix-ui/react-icons"
import { Box, Flex, ScrollArea, Text } from "@radix-ui/themes"
import { Link as RouterLink } from "@tanstack/react-router"
import React, {
  type MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { create } from "zustand"

type SidebarMinifiedState = {
  isMinified: true
  state: "extended" | "minified"
}

type SidebarFullState = {
  isMinified: false
  state: "extended" | "closed"
}

type SidebarState = SidebarMinifiedState | SidebarFullState

type SidebarStore = SidebarState & {
  isHovered: boolean
  currentlyExpanded: () => boolean
  toggleOpen: () => void
  toggleMinified: () => void
  setHovered: (hovered: boolean) => void
}

export const useSidebarStore = create<SidebarStore>((set, get) => {
  const setMinified = (
    minified: boolean,
    current: SidebarStore
  ): Partial<SidebarStore> => {
    if (minified) {
      return {
        isMinified: true,
        state: current.state === "extended" ? "extended" : "minified",
      }
    }

    return {
      isMinified: false,
      state: current.state === "extended" ? "extended" : "closed",
    }
  }

  const setOpen = (
    open: boolean,
    current: SidebarStore
  ): Partial<SidebarStore> => {
    if (open) {
      return { state: "extended" }
    }

    return { state: current.isMinified ? "minified" : "closed" }
  }

  return {
    isMinified: true,
    state: "extended",
    isHovered: false,
    currentlyExpanded: () =>
      get().state === "extended" || (get().isMinified && get().isHovered),
    setOpen: (open: boolean) => set((current) => setOpen(open, current)),
    toggleOpen: () =>
      set((current) => setOpen(current.state !== "extended", current)),
    setMinified: (minified: boolean) =>
      set((current) => setMinified(minified, current)),
    toggleMinified: () =>
      set((current) => setMinified(!current.isMinified, current)),
    setHovered: (hovered: boolean) =>
      set((current) => ({ ...current, isHovered: hovered })),
  }
})

export function Sidebar() {
  const sidebarState = useSidebarStore()
  const { isMinified, setHovered, currentlyExpanded, state } = sidebarState
  const { t } = useTranslation("sidebar")

  const isExpanded = currentlyExpanded()

  const sidebarWidth = isExpanded
    ? "w-sidebar"
    : isMinified
      ? "w-14"
      : state === "extended"
        ? "w-sidebar"
        : "w-0"

  return (
    <Box
      asChild
      className={`bg-gray-3 transition-all duration-300 ease-in-out ${sidebarWidth}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <aside
        className={
          "border-gray-6 border-r transition-all duration-300 ease-in-out"
        }
      >
        <ScrollArea className="h-full">
          <Flex direction="column" gap="1" p="4">
            <SidebarItem
              icon={<DashboardIcon />}
              label={t("dashboard")}
              to="/"
              sidebarState={sidebarState}
            />
            <SidebarItem
              icon={<EnvelopeClosedIcon />}
              label={t("inbox")}
              to="/inbox"
              badge={3}
              sidebarState={sidebarState}
            />
            <SidebarItem
              icon={<BackpackIcon />}
              label={t("ecommerce")}
              to="/e-commerce"
              expandable
              sidebarState={sidebarState}
            />
            <SidebarItem
              icon={<PersonIcon />}
              label={t("users")}
              to="/users"
              expandable
              sidebarState={sidebarState}
            >
              <SidebarSubItem
                label={t("usersList")}
                to="/users/list"
                sidebarState={sidebarState}
              />
              <SidebarSubItem
                label={t("profile")}
                to="/profile"
                sidebarState={sidebarState}
              />
              <SidebarSubItem
                label={t("feed")}
                to="/users/feed"
                sidebarState={sidebarState}
              />
              <SidebarSubItem
                label={t("settings")}
                to="/settings"
                sidebarState={sidebarState}
              />
            </SidebarItem>
            <SidebarItem
              icon={<FileTextIcon />}
              label={t("pages")}
              to="/pages"
              expandable
              sidebarState={sidebarState}
            />
            <SidebarItem
              icon={<LockClosedIcon />}
              label={t("authentication")}
              to="/auth"
              expandable
              sidebarState={sidebarState}
            />
            <SidebarItem
              icon={<CubeIcon />}
              label={t("organizations")}
              to="/organizations"
              sidebarState={sidebarState}
            />
            <SidebarItem
              icon={<ReaderIcon />}
              label={t("docs")}
              to="/docs"
              sidebarState={sidebarState}
            />
            <SidebarItem
              icon={<ComponentInstanceIcon />}
              label={t("components")}
              to="/components"
              sidebarState={sidebarState}
            />
            <SidebarItem
              icon={<QuestionMarkCircledIcon />}
              label={t("help")}
              to="/help"
              sidebarState={sidebarState}
            />
          </Flex>
        </ScrollArea>
      </aside>
    </Box>
  )
}

function SidebarItem({
  icon,
  label,
  to,
  badge,
  expandable,
  children,
  sidebarState,
}: {
  icon: React.ReactNode
  label: string
  to: string
  badge?: number
  expandable?: boolean
  children?: React.ReactNode
  sidebarState: SidebarStore
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState<number | undefined>(0)

  const isSidebarExpanded = sidebarState.currentlyExpanded()
  const showFullLabel = !sidebarState.isMinified || isSidebarExpanded

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isExpanded ? contentRef.current.scrollHeight : 0)
    }
  }, [isExpanded])

  const toggleExpand: MouseEventHandler<"a"> = (e) => {
    if (expandable) {
      e.preventDefault()
      setIsExpanded(!isExpanded)
    }
  }

  const renderIcon = () => (
    <Box className="flex h-6 w-6 items-center justify-center">{icon}</Box>
  )

  const renderLabel = () =>
    showFullLabel && (
      <Text ml="3" className="flex-grow">
        {label}
      </Text>
    )

  const renderBadge = () =>
    showFullLabel &&
    badge && (
      <Box className="ml-auto rounded-full bg-blue-9 px-2 py-1 font-semibold text-white text-xs">
        {badge}
      </Box>
    )

  const renderExpandIcon = () =>
    showFullLabel &&
    expandable && (
      <ChevronDownIcon
        color="gray"
        className={`ml-2 h-4 w-4 transition-transform duration-200 ${isExpanded ? "rotate-180" : ""}`}
      />
    )

  const renderChildren = () =>
    expandable && (
      <Box
        ref={contentRef}
        className="overflow-hidden transition-all duration-300 ease-in-out"
        style={{ maxHeight: contentHeight }}
      >
        <Flex
          direction="column"
          gap="1"
          ml={showFullLabel ? "6" : "0"}
          mt="2"
          mb="2"
        >
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              const { label, to } = child.props
              return (
                <SidebarSubItem
                  key={to}
                  label={label}
                  to={to}
                  sidebarState={sidebarState}
                />
              )
            }
            return child
          })}
        </Flex>
      </Box>
    )

  return (
    <Box>
      <RouterLink
        to={to}
        className={`flex h-10 items-center justify-between rounded py-2 transition-all duration-300 ease-in-out ${isSidebarExpanded ? "px-4" : "px-0 hover:px-4"} hover:bg-gray-7`}
        onClick={toggleExpand}
      >
        <Flex align="center" className="w-full">
          {renderIcon()}
          {renderLabel()}
          {renderBadge()}
          {renderExpandIcon()}
        </Flex>
      </RouterLink>
      {renderChildren()}
    </Box>
  )
}

function SidebarSubItem({
  label,
  to,
  sidebarState,
}: { label: string; to: string; sidebarState: SidebarStore }) {
  const initials = label
    .split(" ")
    .map((word) => word[0])
    .join("")
    .slice(0, 2)
    .toUpperCase()
    .slice(0, 2)

  const isSidebarExpanded = sidebarState.currentlyExpanded()
  const showFullLabel = !sidebarState.isMinified || isSidebarExpanded
  return (
    <RouterLink
      to={to}
      className={`block rounded py-2 text-gray-12 transition-all duration-300 ease-in-out ${showFullLabel ? "px-4" : "px-0 hover:px-4"} hover:bg-gray-7`}
    >
      <Flex align="center">
        {showFullLabel ? (
          <Text>{label}</Text>
        ) : (
          <Box className="flex h-6 w-6 items-center justify-center">
            {initials}
          </Box>
        )}
      </Flex>
    </RouterLink>
  )
}
