"use client"
import { CardContainer } from "@incmixf/components/atoms/card-container"
import LoadingPage from "@incmixf/components/pages/loading-page"
import { useCurrentUser } from "@incmixf/utils/user"
import { Button, Container, Flex, Heading } from "@radix-ui/themes"
import { useMutation } from "@tanstack/react-query"
import { Link, useNavigate } from "@tanstack/react-router"
import { useEffect } from "react"

function HomePage() {
  const { user, isLoading, isError } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !isLoading && !isError) {
      navigate({ to: "/profile" })
    }
  }, [user, isLoading, isError, navigate])

  const { mutate: testSentry } = useMutation({
    mutationKey: ["test-sentry"],
    mutationFn: () => {
      const url = `${import.meta.env["VITE_AUTH_API_URL"]}/test-sentry`
      return fetch(url)
    },
  })

  if (isLoading) {
    return <LoadingPage />
  }

  if (user) {
    return null
  }

  return (
    <Container>
      <Flex height="100vh" align="center" justify="center">
        <CardContainer>
          <Flex direction="column" align="center" gap="4">
            <Heading size="6" align="center">
              Welcome to Fihi App
            </Heading>
            <Flex gap="4">
              <Button asChild>
                <Link to="/login">Login</Link>
              </Button>
              <Button asChild>
                <Link to="/signup">Sign Up</Link>
              </Button>
              <Button onClick={() => testSentry()} color="red">
                Test sentry
              </Button>
            </Flex>
          </Flex>
        </CardContainer>
      </Flex>
    </Container>
  )
}

export default HomePage
