"use client"
import NotificationsPage from "@incmixf/components/pages/notifications-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/notifications",
  component: () => <NotificationsPage />,
})
