import { CurrentUserProfileImage } from "@incmixf/components/molecules/user-profile-image"
import { useThemeStore } from "@incmixf/store/theme"
import {
  BellIcon,
  GearIcon,
  HamburgerMenuIcon,
  MagnifyingGlassIcon,
  MoonIcon,
  SunIcon,
} from "@radix-ui/react-icons"
import { Box, Button, Flex, Link, TextField } from "@radix-ui/themes"
import { Link as RouterLink } from "@tanstack/react-router"
import { useTranslation } from "react-i18next"
import { useSidebarStore } from "./sidebar"

const NavbarLogo: React.FC = () => (
  <Link asChild href="/">
    <Flex align="center">
      <svg
        className="mr-2 h-8 w-8 text-blue-10"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <title>IncMix</title>
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
      </svg>
      <Box as="span" className="font-semibold text-gray-12 text-xl">
        IncMix
      </Box>
    </Flex>
  </Link>
)

const SearchBar: React.FC = () => {
  const { t } = useTranslation("navbar")
  return (
    <Box position="relative">
      <TextField.Root placeholder={t("search")} className="rounded-4 bg-gray-7">
        <TextField.Slot />
        <TextField.Slot>
          <MagnifyingGlassIcon className="text-gray-11" />
        </TextField.Slot>
      </TextField.Root>
    </Box>
  )
}

const ThemeToggle: React.FC = () => {
  const { theme, toggleTheme } = useThemeStore()
  const { t } = useTranslation("navbar")
  return (
    <Button variant="ghost" onClick={toggleTheme} aria-label={t("toggleTheme")}>
      {theme === "dark" ? (
        <SunIcon className="h-6 w-6 text-gray-12" />
      ) : (
        <MoonIcon className="h-6 w-6 text-gray-12" />
      )}
    </Button>
  )
}

const NavbarIcons: React.FC = () => {
  const { t } = useTranslation("navbar")
  return (
    <Flex align="center" gap="4">
      <ThemeToggle />
      <RouterLink to="/notifications">
        <BellIcon
          className="h-6 w-6 cursor-pointer text-gray-12"
          aria-label={t("notifications")}
        />
      </RouterLink>
      <RouterLink to="/settings">
        <GearIcon
          className="h-6 w-6 cursor-pointer text-gray-12"
          aria-label={t("settings")}
        />
      </RouterLink>
      <RouterLink to="/profile" className="rounded-full">
        <CurrentUserProfileImage size="2" aria-label={t("profile")} />
      </RouterLink>
    </Flex>
  )
}

export function NavbarMain() {
  const { t } = useTranslation("navbar")
  const { toggleOpen } = useSidebarStore()

  return (
    <Flex
      asChild
      direction="column"
      justify="center"
      className="z-50 h-navbar w-full border-gray-6 border-b bg-gray-3 text-white"
    >
      <nav>
        <Box className="px-4 py-3">
          <Flex justify="between" align="center" className="h-full">
            <Flex align="center" gap="4">
              <Button
                variant="ghost"
                onClick={toggleOpen}
                aria-label={t("toggleSidebar")}
              >
                <HamburgerMenuIcon className="h-6 w-6 text-gray-12 transition-colors duration-200 hover:text-white" />
              </Button>
              <NavbarLogo />
              <SearchBar />
            </Flex>
            <NavbarIcons />
          </Flex>
        </Box>
      </nav>
    </Flex>
  )
}
