import { enTranslations } from "@incmixf/react-i18n/src/translations/en"
import { ptTranslations } from "@incmixf/react-i18n/src/translations/pt"
import i18n from "i18next"
import ChainedBackend, {
  type ChainedBackendOptions,
} from "i18next-chained-backend"
import HttpBackend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init<ChainedBackendOptions>({
    resources: {
      en: enTranslations,
      pt: ptTranslations,
    },
    debug: true,
    lng: "en",
    fallbackLng: "en",
    partialBundledLanguages: true,
    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          loadPath:
            "http://localhost:9090/api/intl/messages/namespaces/{{lng}}/{{ns}}",
          crossDomain: true,
          parse: (data: string) => {
            return JSON.parse(data)
          },
        },
      ],
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  })

export { i18n }
