import type { I18nTranslations } from "@incmixf/react-i18n/src/types/i18n"

export const ptTranslations: I18nTranslations = {
  login: {
    title: "Entrar",
    emailValidation: "Por favor, insira um email válido",
    passwordValidation: "Por favor, insira sua senha",
    submit: "Entrar",
    loggingIn: "Iniciando sessão...",
    googleLogin: "Entrar com Google",
    signupPrompt: "Não tem uma conta? Cadastre-se",
    forgotPassword: "Esqueceu sua senha?",
    error: {
      googleAuthUrl: "Falha ao recuperar URL de autenticação",
      login: "Erro ao efetuar login",
      logout: "Erro ao efetuar logout",
    },
    success: {
      login: "Login efetuado com sucesso",
      logout: "Logout efetuado com sucesso",
    },
  },
  signup: {
    title: "Cadastro",
    fullNameValidation: "Por favor, insira seu nome",
    emailValidation: "Por favor, insira um email válido",
    passwordValidation: "Por favor, insira sua senha",
    submit: "Cadastrar",
    signingUp: "Cadastrando...",
    signupSuccess: "Cadastro realizado com sucesso",
    loginPrompt: "Já tem uma conta? Faça login",
    error: {
      signup: "Erro ao cadastrar",
    },
  },
  emailVerification: {
    title: "Verificação de Email",
    emailValidation: "Por favor, insira um email válido",
    codeValidation: "Por favor, insira o código de verificação",
    verifying: "Verificando",
    verified: "Verificado",
    submit: "Verificar",
  },
  common: {
    fullName: "Nome Completo",
    email: "Email",
    password: "Senha",
    loading: "Carregando...",
    back: "Voltar",
    you: "Você",
    remove: "Remover",
    confirm: "Confirmar",
    cancel: "Cancelar",
    verificationCode: "Código de Verificação",
  },
  settings: {
    settings: "Configurações",
    generalInfo: "Informações gerais",
    nameRequired: "O nome é obrigatório",
    saving: "Salvando...",
    saveChanges: "Salvar alterações",
    changePassword: "Alterar senha",
    currentPassword: "Senha atual",
    currentPasswordRequired: "A senha atual é obrigatória",
    newPassword: "Nova senha",
    newPasswordLength: "A nova senha deve ter pelo menos 8 caracteres",
    confirmNewPassword: "Confirmar nova senha",
    confirmPasswordRequired: "Por favor, confirme sua nova senha",
    organizations: "Organizações",
    organization: "Organização",
    languageSettings: "Configurações de Idioma",
    selectLanguage: "Selecionar Idioma",
    sidebarSettings: "Configurações da Barra Lateral",
    minifySidebar: "Minificar Barra Lateral",
    themeSettings: "Configurações de Tema",
    darkMode: "Modo Escuro",
  },
  profile: {
    profileInformation: "Informações do Perfil",
    logout: "Sair",
    error: {
      uploadProfilePicture: "Erro ao enviar foto de perfil",
      addProfilePicture: "Erro ao adicionar foto de perfil",
      updateUser: "Erro ao atualizar usuário",
      deleteProfilePicture: "Erro ao deletar foto de perfil",
      changePassword: "Erro ao alterar senha",
    },
    success: {
      uploadProfilePicture: "Foto de perfil enviada com sucesso",
      addProfilePicture: "Foto de perfil adicionada com sucesso",
      updateUser: "Usuário atualizado com sucesso",
      deleteProfilePicture: "Foto de perfil deletada com sucesso",
      changePassword: "Senha alterada com sucesso",
    },
  },
  dbConnections: {
    dbConnections: "DB Connections",
    addNewConnection: "Add New Connection",
    connectionName: "Connection Name",
    connectionString: "Connection String",
    host: "Host",
    port: "Port",
    username: "Username",
    password: "Password",
    testConnection: "Test Connection",
    testingConnection: "Testing Connection...",
    connectionTestSuccess: "Connection test successful",
    connectionTestFailed: "Connection test failed",
    deleteConnection: "Delete Connection",
    deleteConfirmation: "Are you sure you want to delete {name}?",
    connectionNameRequired: "Connection name is required",
    connectionStringRequired: "Connection string is required",
    hostRequired: "Host is required",
    portRequired: "Port is required",
    usernameRequired: "Username is required",
    passwordRequired: "Password is required",
  },
  sidebar: {
    dashboard: "Painel",
    inbox: "Caixa de entrada",
    ecommerce: "E-commerce",
    users: "Usuários",
    usersList: "Lista de usuários",
    profile: "Perfil",
    feed: "Feed",
    settings: "Configurações",
    pages: "Páginas",
    organizations: "Organizações",
    authentication: "Autenticação",
    docs: "Documentação",
    components: "Componentes",
    help: "Ajuda",
  },
  navbar: {
    toggleSidebar: "Alternar barra lateral",
    search: "Pesquisar",
    toggleTheme: "Alternar tema",
    notifications: "Notificações",
    settings: "Configurações",
    profile: "Perfil",
  },
  organizations: {
    title: "Organizações",
    createOrganization: "Criar Organização",
    name: "Nome",
    members: "Membros",
    loading: "Carregando...",
    createNewOrganization: "Criar Nova Organização",
    organizationName: "Nome da Organização",
    create: "Criar",
    creating: "Criando...",
    noOrganizations: "Você ainda não faz parte de nenhuma organização.",
    errorLoading: "Falha ao carregar organizações. Por favor, tente novamente.",
  },
  organizationDetails: {
    notFound: "Organização não encontrada",
    deleteOrganization: "Excluir Organização",
    members: "Membros",
    name: "Nome",
    email: "E-mail",
    role: "Função",
    actions: "Ações",
    newMemberEmail: "E-mail do Novo Membro",
    addMember: "Adicionar Membro",
    cannotRemoveSelf: "Você não pode remover a si mesmo",
    deleteConfirmation: "Tem certeza que deseja excluir {name}?",
    editRole: "Editar função",
    editName: "Editar nome",
    success: {
      addMember: "Membro adicionado com sucesso",
      updateMemberRole: "Função do membro atualizada com sucesso",
      deleteOrganization: "Organização excluída com sucesso",
      updateOrganizationName: "Nome da organização atualizado com sucesso",
    },
    error: {
      addMember: "Erro ao adicionar membro",
      updateMemberRole: "Erro ao atualizar função do membro",
      deleteOrganization: "Erro ao excluir organização",
      updateOrganizationName: "Erro ao atualizar nome da organização",
    },
  },
  roles: {
    owner: "Proprietário",
    admin: "Administrador",
    editor: "Editor",
    commenter: "Comentador",
    viewer: "Visualizador",
  },
  pageNotFound: {
    title: "Página Não Encontrada",
    message: "A página que você está procurando não existe.",
    backToHome: "Voltar para a página inicial",
  },
}
