"use client"
import TestPage from "@incmixf/components/pages/test-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/test",
  component: () => <TestPage />,
})
