"use client"
import SettingsPage from "@incmixf/components/pages/settings-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/settings",
  component: () => <SettingsPage />,
})
