import { type ButtonProps, Button as RadixButton } from "@radix-ui/themes"
import React from "react"

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, disabled, ...props }, ref) => {
    const defaultClassName = `${disabled ? "cursor-not-allowed" : "cursor-pointer"} `

    return (
      <RadixButton
        {...props}
        className={`${defaultClassName}${className}`}
        ref={ref}
        disabled={disabled}
      >
        {children}
      </RadixButton>
    )
  }
)

Button.displayName = "Button"
