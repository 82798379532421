"use client"
import SignupPage from "@incmixf/auth/components/pages/signup-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/signup",
  component: () => <SignupPage />,
})
