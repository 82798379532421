"use client"
import GoogleAuthCallbackPage from "@incmixf/auth/components/pages/google-auth-callback-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/auth/google/callback",
  component: () => <GoogleAuthCallbackPage />,
  validateSearch: (search: Record<string, unknown>) => ({
    state: (search["state"] as string) || "",
    code: (search["code"] as string) || "",
  }),
})
