import { router } from "@/instrument"
import QueryProvider from "@/query-client"
import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components"
import LoadingPage from "@incmixf/components/pages/loading-page"
import { i18n, useLanguageStore } from "@incmixf/react-i18n"
import { useThemeStore } from "@incmixf/store/theme"
import { Theme } from "@radix-ui/themes"
import { RouterProvider } from "@tanstack/react-router"
import { Suspense } from "react"

function App() {
  const { theme } = useThemeStore()

  const { language } = useLanguageStore()

  i18n.changeLanguage(language)

  return (
    <QueryProvider>
      <Theme
        accentColor="blue"
        grayColor="slate"
        panelBackground="solid"
        scaling="100%"
        radius="large"
        appearance={theme}
      >
        <Suspense fallback={<LoadingPage />}>
          <FluentProvider
            theme={theme === "light" ? webLightTheme : webDarkTheme}
          >
            <RouterProvider router={router} />
          </FluentProvider>
        </Suspense>
      </Theme>
    </QueryProvider>
  )
}

export default App
