"use client"
import LoadingPage from "@incmixf/components/pages/loading-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/loading",
  component: () => <LoadingPage />,
})
