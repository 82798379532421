"use client"
import LoginPage from "@incmixf/auth/components/pages/login-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/login",
  component: () => <LoginPage />,
})
