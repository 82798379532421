import GoogleAuthCallbackRoute from "@incmixf/auth/routes/google-auth-callback"
import LoadingPage from "@incmixf/components/pages/loading-page"
import { useGoogleAuthCallback } from "@incmixf/utils/user"
import { useNavigate } from "@tanstack/react-router"

function GoogleAuthCallbackPage() {
  const { state, code } = GoogleAuthCallbackRoute.useSearch()
  const { isLoggedIn, isLoading } = useGoogleAuthCallback(state, code)
  const navigate = useNavigate()

  if (isLoading) {
    return <LoadingPage />
  }

  if (isLoggedIn) {
    navigate({ to: "/profile" })
  }

  return null
}

export default GoogleAuthCallbackPage
