import type { I18nTranslations } from "@incmixf/react-i18n/src/types/i18n"

export const enTranslations: I18nTranslations = {
  login: {
    title: "Log-In",
    emailValidation: "Please provide a valid email",
    passwordValidation: "Password must be at least 6 characters long",
    submit: "Login",
    loggingIn: "Logging in...",
    googleLogin: "Login with Google",
    signupPrompt: "Don't have an account? Sign up",
    forgotPassword: "Forgot Password?",
    error: {
      googleAuthUrl: "Failed to retrieve authentication URL",
      login: "Error logging in",
      logout: "Error logging out",
    },
    success: {
      login: "Login successful",
      logout: "Logout successful",
    },
  },
  signup: {
    title: "Sign-Up",
    fullNameValidation: "Please enter your Name",
    emailValidation: "Please provide a valid email",
    passwordValidation: "Password must be at least 6 characters long",
    submit: "Sign Up",
    signingUp: "Signing up...",
    signupSuccess: "Signup successful",
    loginPrompt: "Already have an account? Log in",
    error: {
      signup: "Error signing up",
    },
  },
  emailVerification: {
    title: "Email Verification",
    emailValidation: "Please provide a valid email",
    codeValidation: "Please provide Verification Code",
    verifying: "Verifying",
    verified: "Verified",
    submit: "Verify",
  },
  common: {
    loading: "Loading...",
    fullName: "Full Name",
    email: "Email",
    password: "Password",
    verificationCode: "Verification Code",
    back: "Back",
    you: "You",
    remove: "Remove",
    confirm: "Confirm",
    cancel: "Cancel",
  },
  settings: {
    settings: "Settings",
    generalInfo: "General information",
    nameRequired: "Name is required",
    saving: "Saving...",
    saveChanges: "Save changes",
    changePassword: "Change password",
    currentPassword: "Current password",
    currentPasswordRequired: "Current password is required",
    newPassword: "New password",
    newPasswordLength: "New password must be at least 8 characters",
    confirmNewPassword: "Confirm new password",
    confirmPasswordRequired: "Please confirm your new password",
    organizations: "Organizations",
    organization: "Organization",
    languageSettings: "Language Settings",
    selectLanguage: "Select Language",
    sidebarSettings: "Sidebar Settings",
    minifySidebar: "Minify Sidebar",
    themeSettings: "Theme Settings",
    darkMode: "Dark Mode",
  },
  dbConnections: {
    dbConnections: "DB Connections",
    addNewConnection: "Add New Connection",
    connectionName: "Connection Name",
    connectionString: "Connection String",
    host: "Host",
    port: "Port",
    username: "Username",
    password: "Password",
    testConnection: "Test Connection",
    testingConnection: "Testing Connection...",
    connectionTestSuccess: "Connection test successful",
    connectionTestFailed: "Connection test failed",
    deleteConnection: "Delete Connection",
    deleteConfirmation: "Are you sure you want to delete {name}?",
    connectionNameRequired: "Connection name is required",
    connectionStringRequired: "Connection string is required",
    hostRequired: "Host is required",
    portRequired: "Port is required",
    usernameRequired: "Username is required",
    passwordRequired: "Password is required",
  },
  profile: {
    profileInformation: "Profile Information",
    logout: "Logout",
    error: {
      uploadProfilePicture: "Error uploading profile picture",
      addProfilePicture: "Error adding profile picture",
      updateUser: "Error updating user",
      deleteProfilePicture: "Error deleting profile picture",
      changePassword: "Error changing password",
    },
    success: {
      uploadProfilePicture: "Profile picture uploaded successfully",
      addProfilePicture: "Profile picture added successfully",
      updateUser: "User updated successfully",
      deleteProfilePicture: "Profile picture deleted successfully",
      changePassword: "Password changed successfully",
    },
  },
  sidebar: {
    dashboard: "Dashboard",
    inbox: "Inbox",
    ecommerce: "E-commerce",
    users: "Users",
    usersList: "Users list",
    profile: "Profile",
    feed: "Feed",
    settings: "Settings",
    pages: "Pages",
    organizations: "Organizations",
    authentication: "Authentication",
    docs: "Docs",
    components: "Components",
    help: "Help",
  },
  navbar: {
    toggleSidebar: "Toggle sidebar",
    search: "Search",
    toggleTheme: "Toggle theme",
    notifications: "Notifications",
    settings: "Settings",
    profile: "Profile",
  },
  organizations: {
    title: "Organizations",
    createOrganization: "Create Organization",
    name: "Name",
    members: "Members",
    loading: "Loading...",
    createNewOrganization: "Create New Organization",
    organizationName: "Organization Name",
    create: "Create",
    creating: "Creating...",
    noOrganizations: "You are not a member of any organizations yet.",
    errorLoading: "Failed to load organizations. Please try again.",
  },
  organizationDetails: {
    notFound: "Organization not found",
    deleteOrganization: "Delete Organization",
    members: "Members",
    name: "Name",
    email: "Email",
    role: "Role",
    actions: "Actions",
    newMemberEmail: "New Member Email",
    addMember: "Add Member",
    cannotRemoveSelf: "You cannot remove yourself",
    deleteConfirmation: "Are you sure you want to delete {name}?",
    editRole: "Edit role",
    editName: "Edit name",
    success: {
      addMember: "Member added successfully",
      updateMemberRole: "Member role updated successfully",
      deleteOrganization: "Organization deleted successfully",
      updateOrganizationName: "Organization name updated successfully",
    },
    error: {
      addMember: "Error adding member",
      updateMemberRole: "Error updating member role",
      deleteOrganization: "Error deleting organization",
      updateOrganizationName: "Error updating organization name",
    },
  },
  roles: {
    owner: "Owner",
    admin: "Admin",
    editor: "Editor",
    commenter: "Commenter",
    viewer: "Viewer",
  },
  pageNotFound: {
    title: "Page Not Found",
    message: "The page you are looking for does not exist.",
    backToHome: "Back to Home",
  },
}
