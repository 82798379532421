import { i18n } from "@incmixf/react-i18n/src/i18n"
import { create } from "zustand"
import { persist } from "zustand/middleware"

interface LanguageState {
  language: string
  setLanguage: (lang: string) => void
}

export const useLanguageStore = create<LanguageState>()(
  persist(
    (set) => ({
      language: i18n.language,
      setLanguage: (lang: string) => {
        i18n.changeLanguage(lang)
        set({ language: lang })
      },
    }),
    {
      name: "language-storage",
    }
  )
)
