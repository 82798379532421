import { NavbarMain } from "@incmixf/components/organisms/navbar"
import { Sidebar } from "@incmixf/components/organisms/sidebar"
import { Toaster } from "@incmixf/components/organisms/toaster"
import { Box, Flex } from "@radix-ui/themes"
import type React from "react"

interface PageLayoutProps {
  children: React.ReactNode
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <Flex direction="column" className="min-h-screen">
      <NavbarMain />
      <Flex className="flex-1">
        <Sidebar />
        <Box className={"flex-1 bg-gray-1 p-6 transition-all duration-300"}>
          {children}
        </Box>
      </Flex>
      <Toaster />
    </Flex>
  )
}
