"use client"
import WelcomePage from "@incmixf/auth/components/pages/welcome-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/welcome",
  component: WelcomePage,
  validateSearch: (search: Record<string, unknown>) => ({
    email: (search["email"] as string) || "",
  }),
})
