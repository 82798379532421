import { Button } from "@incmixf/components/atoms/button"
import { CardContainer } from "@incmixf/components/atoms/card-container"
import { FormField } from "@incmixf/components/atoms/form-field"
import LoadingPage from "@incmixf/components/pages/loading-page"
import { useAuth, useLogin } from "@incmixf/utils/user"
import { Box, Container, Flex, Heading, Text } from "@radix-ui/themes"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import { Link } from "@tanstack/react-router"
import { Form } from "houseform"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "sonner"
import { z } from "zod"
const AUTH_API_URL = import.meta.env["VITE_AUTH_API_URL"]

function LoginForm() {
  const { t } = useTranslation(["login", "common"])
  const googleLoginMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(`${AUTH_API_URL}/google`, {
        credentials: "include",
      })
      if (!response.ok) throw new Error(t("error.googleAuthUrl"))
      return response.json()
    },
    onSuccess: (data) => {
      window.location.href = `${data.authUrl}`
    },
    onError: (error: Error) => {
      toast.error(error.message)
    },
  })

  const handleGoogleLogin = () => {
    googleLoginMutation.mutate()
  }

  const { handleLogin, isLoginLoading, loginError } = useLogin()

  const handleSubmit = (values: { email: string; password: string }) => {
    handleLogin(values.email, values.password)
  }

  return (
    <CardContainer>
      <Heading size="4" mb="4" align="center">
        {t("title")}
      </Heading>
      <Form onSubmit={handleSubmit}>
        {({ submit }) => (
          <Flex direction="column" gap="4">
            <FormField
              name="email"
              label={t("common:email")}
              type="email"
              validation={z.string().email(t("emailValidation"))}
            />

            <FormField
              name="password"
              label={t("common:password")}
              type="password"
              validation={z.string().min(1, t("passwordValidation"))}
            />

            {loginError && (
              <Text color="red" size="2">
                {loginError.message}
              </Text>
            )}

            <Button
              onClick={submit}
              color="blue"
              disabled={isLoginLoading}
              className="w-full"
            >
              {isLoginLoading ? t("loggingIn") : t("submit")}
            </Button>
          </Flex>
        )}
      </Form>
      <Button
        onClick={handleGoogleLogin}
        color="red"
        mt="4"
        className="w-full text-white"
      >
        {t("googleLogin")}
      </Button>
      <Box mt="4" className="text-center">
        <Link to="/signup">
          <Text color="blue">{t("signupPrompt")}</Text>
        </Link>
      </Box>
    </CardContainer>
  )
}

function LoginPage() {
  const navigate = useNavigate()
  const { authUser, isLoading, isError } = useAuth()

  useEffect(() => {
    if (authUser && !isLoading && !isError) {
      navigate({ to: "/profile" })
    }
  }, [authUser, isLoading, isError, navigate])

  if (isLoading) {
    return <LoadingPage />
  }

  if (isError || !authUser) {
    return (
      <Container>
        <Flex height="100vh" align="center" justify="center">
          <LoginForm />
        </Flex>
      </Container>
    )
  }

  return null
}

export default LoginPage
