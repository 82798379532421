"use client"
import ProfilePage from "@incmixf/components/pages/profile-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/profile",
  component: () => <ProfilePage />,
})
