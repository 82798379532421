"use client"
import HomePage from "@incmixf/components/pages/home-page"
import RootRoute from "@incmixf/routes/root"
import { createRoute } from "@tanstack/react-router"

export const IndexRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: "/",
  component: () => <HomePage />,
})

export { default as RootRoute } from "@incmixf/routes/root"
export { default as ProfileRoute } from "@incmixf/routes/profile"
export { default as SettingsRoute } from "@incmixf/routes/settings"
export { default as NotificationsRoute } from "@incmixf/routes/notifications"
export { default as TestRoute } from "@incmixf/routes/test"
export { default as NotFoundRoute } from "@incmixf/routes/not-found"
export { default as LoadingRoute } from "@incmixf/routes/loading"
